import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Header from '../views/Header';
import Footer from '../views/Footer';
import TopCarousel from '../parts/TopCarousel';
import PageTopButton from '../parts/PageTopButton';

type Props = {
  data: any
  children?: ReactNode
}

const CategoryLayout = (props: Props) => {
  const { data } = props;
  const MainContainer = styled(Container)`
    padding: 0 25px;
  `;
  const Wrapper = styled.div`
    background-color: #fff;
    @media screen and (max-width: 480px) {
      overflow: hidden;
    }
  `;
  return (
    <Wrapper>
      <Container fluid className="px-0 main">
        <Row className="justify-content-center">
          <Col>
            <Header data={data}/>
          </Col>
        </Row>
      </Container>
      <MainContainer className="mt-3 mb-5">
        <Row>
          <Col lg={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} sm={12} xs={12}>
            <main>{props.children}</main>
          </Col>
        </Row>
      </MainContainer>
      <Container fluid className="px-0">
        <Row>
          <Col className="footer-col">
            <Footer />
          </Col>
        </Row>
      </Container>
      <PageTopButton />
    </Wrapper>
  );
}

export default CategoryLayout;